import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class PaymentPlanService {
  private apiPrefix = '/admin/payment-plan';

  constructor(
    private api: ApiService
  ) {
  }

  getPlanTypes() {
    return this.api.get(`${this.apiPrefix}`);
  }

  setActivePlanType(planTypeId: number) {
    return this.api.patch(`${this.apiPrefix}/${planTypeId}/set-active`, {});
  }

  setPassivePlanType(planTypeId: number) {
    return this.api.patch(`${this.apiPrefix}/${planTypeId}/set-passive`, {});
  }
}
